import '../styles/globals.css';
import Head from 'next/head';
import type { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useCallback, useEffect } from 'react';
import EventEmitter from 'events';
import * as R from 'ramda';
import useSWR, { SWRConfig } from 'swr';
import SHOP_CONFIG from '@shared/config';
import { GA_TRACKING_ID, pageview } from '@shared/utils/google-analytics';
import SharedRouter from '@shared/utils/router';
import event from '@shared/utils/events';
import analytics from '~/analytics';
import { EVENT } from '~/utils/events';
import { sendErrorReport } from '~/utils/errorReport';
import 'regenerator-runtime/runtime';

const isProduction = process.env.NEXT_PUBLIC_MODE === 'production';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { mutate } = useSWR('global://e2e-test');
  const { data: windowMessage, mutate: mutateWindowMessage } = useSWR(
    'global://window-message',
    () => {
      return {} as {
        user?: Record<string, any>;
        headerTitle?: string;
        [key: string]: any;
      };
    },
    {
      revalidateIfStale: false,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const shop = pageProps.query.shop || '';

  const handleMessage = (ev: MessageEvent<any>) => {
    const {
      buzzni_shopping_gpt_access_token: access_token, // 레거시: 단일상품 채팅SDK 혹은 쇼핑어시스턴트 챗봇SDK에서 전달하는 access token
      name = null, // 레거시: 단일상품 채팅SDK에서 전달하는 챗봇 이름
      headerTitle, // 레거시: 단일상품 채팅SDK에서 전달하는 헤더 타이틀
      buzzni_showhost: showhost,
      buzzni_assistant: assistant,

      // 대화형AI SDK
      buzzni_showhost_backhandler,
      buzzni_showhost_michandler,
    } = ev.data ?? {};
    if (
      buzzni_showhost_backhandler ||
      buzzni_showhost_michandler !== undefined
    ) {
      event(EVENT.SHOWHOST_NATIVE_CALL, {
        buzzni_showhost_backhandler,
        buzzni_showhost_michandler,
      });
      return;
    }

    mutateWindowMessage(
      (prev) => ({
        user: {
          ...(windowMessage?.user ?? {}),
          accessToken: access_token,
          name,
        },
        headerTitle,
        ...(!!showhost && {
          showhost: { ...(prev?.showhost ?? {}), ...showhost },
        }),
        ...(!!assistant && {
          assistant: { ...(prev?.assistant ?? {}), ...assistant },
        }),
      }),
      { revalidate: false }
    );
  };

  const handleErrorReport = useCallback(
    ((event: CustomEvent) =>
      sendErrorReport({
        ...event.detail,
        shop: new URLSearchParams(window.location.search).get('shop'),
      })) as EventListener,
    []
  );

  const init = () => {
    (window as any).eventEmitter = new EventEmitter();
    SharedRouter.initialize();
    analytics.initialize();
    mutate({ isReady: true });
    window.parent.postMessage({ buzzni_shopping_gpt_iframe_ready: true }, '*');
    window.addEventListener(EVENT.ERROR, handleErrorReport);
    return () => {
      window.removeEventListener(EVENT.ERROR, handleErrorReport);
    };
  };
  useEffect(init, []);

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      SharedRouter.pushHistory(url);
      if (isProduction) {
        pageview(url);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const handleGlobalStyleChange = () => {
    let obj: Record<string, string> = {};

    if (!!shop && !!SHOP_CONFIG[shop]) {
      const { palette } = SHOP_CONFIG[shop];
      obj = { ...(palette ?? {}) };
    }

    if (windowMessage?.assistant && !R.isEmpty(windowMessage.assistant)) {
      const { primary, primary10 } = windowMessage.assistant;
      obj = {
        ...(!!primary && { primary }),
        ...(!!primary10 && { primary10 }),
      };
    }

    if (!R.isEmpty(obj)) {
      R.pipe(
        R.toPairs,
        R.forEach(([key, value]: [string, string]) => {
          const bodyStyles = document.body.style;
          bodyStyles.setProperty(`--${key}`, value);
        })
      )(obj);
    }
  };

  useEffect(handleGlobalStyleChange, [shop, windowMessage]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage, mutateWindowMessage]);

  return (
    <>
      <Head>
        <title>옥순AI - 쇼핑, 일일이 검색 말고 물어보세요!</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#ffffff" />
        {isProduction && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
        `,
            }}
          />
        )}
      </Head>
      {!!GA_TRACKING_ID && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
      )}
      <Script src="https://cdnjs.cloudflare.com/ajax/libs/smoothscroll/1.4.10/SmoothScroll.min.js.map" />
      <SWRConfig
        value={{
          fallback: pageProps.fallback,
          revalidateOnFocus: false,
          revalidateIfStale: false,
        }}
      >
        <Component {...pageProps} />
      </SWRConfig>
    </>
  );
}

export default MyApp;

MyApp.getInitialProps = async ({ ctx, Component }: AppContext) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  pageProps = {
    ...pageProps,
    query: { ...ctx.query },
    fallback: { 'global://query': { ...ctx.query } },
  };

  return { pageProps };
};
